<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/overhaulDynamic' }">检修任务</el-breadcrumb-item>
          <el-breadcrumb-item>编辑动态</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form v-if="form.baseInfo" :model="cForm" :rules="rules" ref="ruleForm" label-width="100px">
          <div style="margin-bottom: 30px;">
            <el-steps :active="active">
              <el-step title="新检修计划"></el-step>
              <el-step title="检修过程中"></el-step>
              <el-step title="检修审核"></el-step>
            </el-steps> 
          </div>
          <div class="form-title">
            基本信息
            <a :href="'/statistics/wapi/v1/file/export/device/report?archivesId='+form.archivesId" target="_blank"><el-button style="margin-left: 10px;float:right" type="primary" size="mini">导出报告</el-button></a>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="检修代号：">{{form.baseInfo.order}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="来源：">{{form.baseInfo.source == 0 ? '新创建' : form.baseInfo.source == 1 ? '巡检' : '点检'}}</el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">设备信息</div>
          <div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设备别名：">{{form.patrolDeviceInfo.alias}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备识别号：">{{ form.patrolDeviceInfo.ein ? form.patrolDeviceInfo.ein : '无' }}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设施位置：">{{form.patrolDeviceInfo.facilityName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="巡视点名称：">{{form.patrolDeviceInfo.patrolName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设备名称：">{{form.patrolDeviceInfo.deviceName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备类型：">{{form.patrolDeviceInfo.deviceTypeName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="所属部门：">{{form.patrolDeviceInfo.departmentName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="规格参数：">{{form.patrolDeviceInfo.specification}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="投入使用日期：" label-width="130px">
                  {{ form.patrolDeviceInfo.applyTime ? dayjs(form.patrolDeviceInfo.applyTime).format('YYYY-MM-DD'):'' }}
                </el-form-item>
              </el-col>
              <el-col :span="12"></el-col>
            </el-row>
          </div>
          <div v-if="dForm.baseInfo.source != 0">
            <div class="form-title">问题文字描述</div>
            <el-form-item label-width="20px">{{dForm.abnormalTaskInfo.describeContent}}</el-form-item>
            <div class="form-title">巡检工作图片</div>
            <div class="img-box">
              <el-image 
                class="images"
                v-for="(item,index) in dForm.abnormalTaskInfo.fileUrls"
                :key="index"
                :src="item"
                :preview-src-list="[item]">
              </el-image>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="巡检人：">{{dForm.abnormalTaskInfo.submitter ? dForm.abnormalTaskInfo.submitter : '无'}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="提交时间：">
                    {{ dForm.abnormalTaskInfo.completeTime ? dayjs(dForm.abnormalTaskInfo.completeTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- 检修人员配置模块 -->
          <el-collapse v-model="activeNames">
            <el-collapse-item :title="`检修配置 ${index+1}`" :name="`${index+1}`" v-for="(item,index) in form.elementInfos" :key="item.eid">
              <div style="padding: 20px;">
                <!-- 如果是最后一个配置项 可以编辑 -->
                <div v-if="eleLen==index">
                  <div class="form-title config-title">检修人员配置</div>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="检修人员">
                        <div @click="addStaff()" class="staffBox">
                          <div v-if="staffList.length>0">
                            <el-tag v-for="staff in staffList" :key="staff.id" style="margin-right: 4px;">
                              {{ staff.name }}
                            </el-tag>
                          </div>
                          <div v-else style="color: #909399;text-align: center;">请选择检修人员</div>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12"></el-col>
                  </el-row>
                  <div class="form-title config-title">
                      问题文字描述<span style="color: red"> *</span>
                  </div>
                  <el-form-item label-width="20px" prop="description">
                      <el-input v-model="cForm.description" type="textarea" :rows="2" style="width:700px" ></el-input>
                  </el-form-item>
                  <div class="form-title config-title">
                      检修方案（审核人员填）<span style="color: red"> *</span>
                  </div>
                  <el-form-item label-width="20px" prop="plan">
                      <el-input v-model="cForm.plan" type="textarea" :rows="2" style="width:700px" ></el-input>
                  </el-form-item>
                </div>
                <div v-else>
                  <div class="form-title config-title">检修人员配置</div>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="检修人员">
                        <div class="staffBox">
                          <div v-if="item.staffInfos.length>0">
                            <el-tag v-for="staff in item.staffInfos" :key="staff.reconditionStaffId" style="margin-right: 4px;">
                              {{ staff.staffName }}
                            </el-tag>
                          </div>
                          <div v-else style="color: #909399;text-align: center;">无检修人员</div>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12"></el-col>
                  </el-row>
                  <div class="form-title config-title">问题文字描述</div>
                  <el-form-item label-width="20px">{{ item.description }}</el-form-item>
                  <div class="form-title config-title">检修方案（审核人员填）</div>
                  <el-form-item label-width="20px">{{ item.plan }}</el-form-item>
                </div>

                <div v-if="item.resultInfo.resultContent">
                  <div class="form-title config-title">检修结果（检修人员提交）</div>
                  <el-form-item label-width="20px">
                    {{ item.resultInfo.resultContent }}
                  </el-form-item>
                  <div class="form-title config-title">检修图片（检修人员提交）</div>
                  <div style="margin-bottom: 20px;">
                    <el-image 
                      class="images"
                      v-for="(itemImg,indexImg) in item.resultInfo.fileUrls"
                      :key="indexImg"
                      :src="itemImg"
                      :preview-src-list="[itemImg]">
                    </el-image>
                  </div>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="提交人：">{{ item.resultInfo.submitter ? item.resultInfo.submitter : '无' }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="提交时间：">
                        {{ item.resultInfo.completeTime ? dayjs(item.resultInfo.completeTime).format('YYYY-MM-DD HH:mm:ss') : '无' }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          
          <div class="bottom-btn">
            <el-row :gutter="20">
              <el-col :span="10" style="margin-top: 14px;display: flex;">
                <el-button type="primary" size="small" @click="$router.push('/overhaulDynamic')">取消</el-button>
                <el-button type="primary" @click="onSubmit()" size="small">提交</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <el-drawer
        title=""
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="900px">
        <div class="drawer-title">选择检修员</div>
        <div class="drawer-content" v-if="drawer">
          <div class="table-tree">
            <div class="tree">
              <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
              <el-tree
                :data="treeData"
                @node-click="treeRowClick"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                ref="tree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                </span>
              </el-tree>
            </div> 
            <div class="table">
              <el-table :data="tableData" style="width: 100%" height="700">
                <el-table-column width="40">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sex" label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
                </el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="name" label="岗位">
                  <template slot-scope="scope">
                    <div
                      v-for="item in scope.row.positionInfoList"
                      :key="item.positionId"
                    >{{item.positionName}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawer=false">取消</el-button>
          <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      active: 2,
      activeNames: [''],
      isContinue: false,
      noSub: null,
      form: { },
      cForm: {
        description: '',
        plan: '',
        eid: null
      },
      dForm: {
        abnormalTaskInfo: {},
        baseInfo: {},
        elementInfos: {},
        patrolDeviceInfo: {}
      },
      rules: {
        description:[{ required: true, message: '必填项', trigger: 'blur' }],
        plan:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      row: {},
      loading: false,
      drawer: false,
      treeData: [],
      tableData: [],
      departmentTree: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      checkedStaffList: [],
      staffListID: [],
      staffList: [], // 检修人员列表
      eleLen: 0,
      deleteReconditionStaffIds: []
    }
  },
  methods: {
    dayjs,
    // 获取检修计划详情数据
    loadDynamicData() {
      this.loading = true
      this.$ajax.post("reconditionDynamicDetail", {
        reconditionId: this.row.reconditionId
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        let len = res.data.elementInfos.length
        this.noSub = res.data.elementInfos[len-1].resultInfo.resultContent
        this.eleLen = res.data.elementInfos.length-1
        // 默认展示最后一项下拉菜单
        this.activeNames.push((res.data.elementInfos.length).toString())
        // 详情数据
        this.form = res.data
        this.dForm = res.data
        // 最后一项人员数据回显
        res.data.elementInfos[this.eleLen].staffInfos.forEach((v)=>{
           this.staffList.push({
             id: v.staffId,
             name: v.staffName,
             reconditionStaffId: v.reconditionStaffId
           })
        })
        // 最后一项描述、方案数据回显
        this.cForm.description = res.data.elementInfos[this.eleLen].description
        this.cForm.plan = res.data.elementInfos[this.eleLen].plan
        this.cForm.eid = res.data.elementInfos[this.eleLen].eid

        this.loading = false
        if(this.form.elementInfos[0].resultInfo.resultContent==null){
          this.active = 1
        }
      }).catch(err=>{
        this.loading = false
      })
    },
    // 选择巡检员
    addStaff() {
      this.loadTableData(this.treeData[0].id)
      this.staffListID = []
      this.staffList.forEach(item => {
        this.staffListID.push(item.id)
      })
      this.checkedStaffList = [].concat(this.staffList)

      this.drawer = true
    },
    // 获取部门结构树
    loadTreeData() {
      this.$ajax.post('departmentResponsibleChild').then(res => {
        this.treeData = res.data
      })
    },
    // 获取部门人员
    loadTableData(id) {
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        res.data.forEach(item => {
          let inIs = this.staffListID.find(function(obj) {
            return obj == item.id
          })
          if (!inIs) {
            item.check = false
          }else {
            item.check = true
          }
        })
        this.tableData = res.data
      })
    },
    // 选择部门结构
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    // 勾选巡检员
    checked(row) {
      if (!this.staffListID.includes(row.id)) {
        this.checkedStaffList.push(row)
        this.staffListID.push(row.id)
      } else {
        this.checkedStaffList.forEach((item, index) => {
          if (item.id == row.id) {
            if(item.reconditionStaffId) {
              this.deleteReconditionStaffIds.push(item.reconditionStaffId)
            }
            this.checkedStaffList.splice(index, 1)
            this.$delete(this.staffListID, index)
            console.log(this.staffListID)
          }
        })
      }
    },
    // 选择巡检员点击确定
    drawerOK() {
      this.drawer = false
      this.staffList = [].concat(this.checkedStaffList)
      this.staffListID = [].concat(this.staffListID)
    },
    // 提交继续检修
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let formData = {
            editElementInfo: {
                addStaffInfos: [],
                deleteReconditionStaffIds: [],
                description: null,
                eid: null,
                lastEid: null,
                plan: null
            },
            reconditionId: this.row.reconditionId
          }
          formData.editElementInfo.description = this.cForm.description
          formData.editElementInfo.eid = this.cForm.eid
          formData.editElementInfo.lastEid = this.cForm.eid
          formData.editElementInfo.plan = this.cForm.plan

          // 新增人员
          this.staffList.forEach(v=>{
            if(!v.reconditionStaffId) {
              formData.editElementInfo.addStaffInfos.push({
                staffId: v.id,
                staffName: v.name
              })
            }
          })
          // 删除人员
          formData.editElementInfo.deleteReconditionStaffIds = this.deleteReconditionStaffIds 

          if (this.staffList.length == 0) {
            this.$message.error("人员配置不能为空！");
            return
          }
          this.loading = true
          this.$ajax.post("reconditionContinueUpdate", formData).then((res) => {
            this.loading = false
            this.$message.success("成功");
            this.$router.push("/overhaulDynamic");
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/overhaulDynamic");
      return;
    }
    this.row = this.$route.params.row;
    this.loadDynamicData()
    this.loadTreeData()
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1000px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 390px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #e7e9ee;
}
.config-title {
  font-size: 15px;
}
.images {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  margin: 0 8px 8px 0;
  display: inline-block;
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>